//球体自转
export const sphereRotation = (data, scene) => {
    scene.children.forEach((e) => {
        //过滤出星球
        if (e.isPlanet) {
            let planetData = data.filter((d) => d.name == e.name)[0]
            if (e.name == '土星') {
                e.rotation.x = 0.05 * 2 * Math.PI
                // return;
            }
            //天王星自转轴特殊
            if (e.name == '天王星') {
                e.rotation.z =
                    e.rotation.z + planetData.rotation >= 2 * Math.PI ? 0 : e.rotation.z + planetData.rotation
                return
            }
            e.rotation.y =
                e.rotation.y + planetData.rotation >= 2 * Math.PI ? 0 : e.rotation.y + planetData.rotation
        }
    })
}
