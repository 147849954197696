// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/b4.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/b9.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-module_t1-ppyHI {\n  font-size: 18px;\n  color: #fff;\n  width: 100%;\n  height: 32px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  line-height: 30px;\n  padding: 0 20px 0 46px;\n  display: flex;\n  justify-content: space-between;\n  position: relative;\n  z-index: 9;\n}\n.content-module_t2-VUywj {\n  font-family: 'mtip';\n}\n.content-module_c-rqNfX {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  margin-bottom: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Page2/Components/content.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,UAAA;AACJ;AAEA;EACI,mBAAA;AAAJ;AAGA;EACI,mDAAA;EACA,mBAAA;AADJ","sourcesContent":[".t1 {\n    font-size: 18px;\n    color: #fff;\n    width: 100%;\n    height: 32px;\n    background: url('../images/b4.png') no-repeat center;\n    line-height: 30px;\n    padding: 0 20px 0 46px;\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    z-index: 9;\n}\n\n.t2 {\n    font-family: 'mtip';\n}\n\n.c {\n    background: url('../images/b9.png');\n    margin-bottom: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"t1": "content-module_t1-ppyHI",
	"t2": "content-module_t2-VUywj",
	"c": "content-module_c-rqNfX"
};
export default ___CSS_LOADER_EXPORT___;
