import * as TWEEN from '@tweenjs/tween.js'
import React, { useEffect, useState } from 'react'

export const Tw = ({ value = 0, dot = 0, max = 0, time = 3 }) => {
    const nv = Number(value)
    const [num, setNum] = useState(0)
    useEffect(() => {
        setNum(0)
        new TWEEN.Tween({ start: 0 })
            .repeat(Infinity)
            .to({ start: nv }, 1000 * 3)
            .repeatDelay(1000 * 60)
            .onUpdate(function (obj) {
                let num = Number(obj.start.toFixed(dot))
                setNum(num)
            })
            // .easing(TWEEN.Easing.Sinusoidal.InOut)
            .start()
    }, [nv])
    return <span>{num}</span>
}
