
// 创建星辰
import * as THREE from 'three'

import starImg from '../img/star.jpg' //星辰

export const createStars = (scene) => {
    const positions = []
    const colors = []
    //星辰几何体
    const starsGeometry = new THREE.BufferGeometry()
    //添加星辰的颜色与位置
    for (let i = 0; i < 10000; i++) {
        let vertex = new THREE.Vector3()
        vertex.x = Math.random() * 2 - 1
        vertex.y = Math.random() * 2 - 1
        vertex.z = Math.random() * 2 - 1
        // @ts-ignore
        positions.push(vertex.x, vertex.y, vertex.z)
        let color = new THREE.Color()
        color.setRGB(255, 255, 255)
        // @ts-ignore
        colors.push(color.r, color.g, color.b)
    }
    starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
    starsGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
    //星辰材质
    let starsMaterial = new THREE.PointsMaterial({
        map: new THREE.TextureLoader().load(starImg),
        size: 5,
        blending: THREE.AdditiveBlending,
        fog: true,
        depthTest: false, //(不能与blending一起使用)
        // depthWrite: false, //(深度写入)防止星辰在球体前面出现黑块
    })
    //星辰的集合
    let starsMesh = new THREE.Points(starsGeometry, starsMaterial)
    starsMesh.scale.set(7000, 7000, 7000) //设置集合体范围
    scene.add(starsMesh)
}