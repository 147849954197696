// 球体公转

export const sphereRevolution = (data, scene) => {
    scene.children.forEach((e) => {
        //过滤出星球
        if (e.isPlanet) {
            let planetData = data.filter((d) => d.name == e.name)[0] //获取球体数据
            e.angle = e.angle + planetData.revolution >= 2 * Math.PI ? 0 : e.angle + planetData.revolution
            e.position.set(
                planetData.position[0] * Math.sin(e.angle),
                0,
                planetData.position[0] * Math.cos(e.angle),
            )
        }
    })
}