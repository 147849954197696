import React from 'react'

import styles from '../styles.module.less'

const Title = ({ children, ...rest }) => {
    return (
        <div className={styles.t10} {...rest}>
            {children}
        </div>
    )
}

export default Title
