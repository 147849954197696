import * as TWEEN from '@tweenjs/tween.js'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as THREE from 'three'

import { addDirLight } from './fc/addDirLight'
import { createOrbitControls } from './fc/createOrbitControls'
import { createSphere } from './fc/createSphere'
import { createStars } from './fc/createStars'
import { createTrack } from './fc/createTrack'
import { createUniverse } from './fc/createUniverse'
import { flyTo } from './fc/flyTo'
import { sphereRevolution } from './fc/sphereRevolution'
import { sphereRotation } from './fc/sphereRotation'
import planetData from './planetData'
import styles from './styles.module.less'

import 'swiper/less'
import 'swiper/less/navigation'
import 'swiper/less/pagination'

const App = () => {
    let config = {
        animationType: false, // 是否显示卫星
        rotationY: true, // 地球自动旋转
        mask: false,
        move: false,
        isDown: false,
        isRevolution: true,
        isRotation: true,
    }
    const navgate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [_html, _setHtml] = useState('')

    useEffect(() => {
        let camera
        let renderer
        let orbitControls
        let mp2: any = {}
        let mp2Over: any = {}

        const mainDom: any = document.getElementById('main')
        const rleft = mainDom.getBoundingClientRect().left

        const scene = new THREE.Scene()
        const textureLoader = new THREE.TextureLoader()
        const raycaster = new THREE.Raycaster()
        const pointer = new THREE.Vector2(-1, -1)

        // const innerWidth = 1920
        // const innerHeight = 1080

        const innerWidth = window.innerWidth
        const innerHeight = window.innerHeight

        const initRenderer = () => {
            renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
            // renderer.setPixelRatio(window.devicePixelRatio)
            renderer.setSize(innerWidth, innerHeight)
            renderer.setClearColor(0x000000, 0.1)
            renderer.shadowMap.enabled = true
            renderer.shadowMap.type = THREE.PCFSoftShadowMap

            console.log(renderer)
        }

        const initCamera = () => {
            camera = new THREE.PerspectiveCamera(45, innerWidth / innerHeight, 1, 50000) //创建透视相机(视场、长宽比、近面、远面)
            camera.position.set(0, 500, 2700) //设置相机位置
            camera.lookAt(0, 0, 0)
            camera.layers.enableAll()
        }

        const onPointerMove = (event) => {
            const dom = document.getElementById('root')
            const scrollTop = dom?.scrollTop
            // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
            pointer.x = ((event.clientX - rleft) / innerWidth) * 2 - 1
            pointer.y = -((event.clientY + scrollTop) / innerHeight) * 2 + 1
        }

        //双击事件
        const handleDblclick = (e) => {
            onPointerMove(e)
            // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
            raycaster.setFromCamera(pointer, camera)
            //生成星球网格列表
            let palnetMeshList = []
            scene.children.forEach((p) => {
                if (p.name !== '') {
                    // @ts-ignore
                    palnetMeshList.push(p)
                }
            })
            // 获取raycaster直线和星球网格列表相交的集合
            let intersects = raycaster.intersectObjects(palnetMeshList)
            //判断是否点击到虚无的太空
            if (intersects.length == 0) {
                return
            }
            let clickPlanet

            //判断是否是行星
            if (intersects[0].object.isPlanet) {
                clickPlanet = intersects[0].object
            } else {
                clickPlanet = intersects[0].object.parent
            }
            // console.log(this.clickPlanet);
            //获取球体半径
            let planetR
            planetData.forEach((e) => {
                if (e.name == clickPlanet.name) {
                    planetR = e.size
                }
            })
            //相机新位置
            let newP = {
                x: clickPlanet.position.x,
                y: clickPlanet.position.y + planetR * 2.5,
                z: clickPlanet.position.z + planetR * 5,
            }
            //双击到星球需要停止公转（双击虚空需反转公转状态）
            if (clickPlanet.type !== 'Scene') {
                config.isRevolution = false
                config.isRotation = false
                //点击后传入参数飞向星球
                flyTo(
                    camera.position,
                    orbitControls.target,
                    newP,
                    clickPlanet.position,
                    1000,
                    camera,
                    orbitControls,
                    () => {
                        if (clickPlanet.name == '地球') {
                            navgate('/page1')
                        } else {
                            setIsModalOpen(true)
                            _setHtml(clickPlanet.planetMsg.d)
                        }
                    },
                )
            } else {
                config.isRevolution = !config.isRevolution
                config.isRotation = !config.isRotation
            }
        }

        function animate() {
            //请求动画帧，屏幕每刷新一次调用一次，绑定屏幕刷新频率
            requestAnimationFrame(animate) //记录下动画id可用于销毁场景
            orbitControls.update() //鼠标控件实时更新
            renderer.render(scene, camera)
            //控制公转
            if (config.isRevolution) {
                sphereRevolution(planetData, scene) //球体公转
            }
            if (config.isRotation) {
                sphereRotation(planetData, scene) //球体自转
            }

            TWEEN.update() //更新动画
        }

        function init() {
            // const { innerWidth, innerHeight } = window
            initCamera()
            initRenderer()
            createUniverse(scene, mp2) //创建宇宙
            createStars(scene) //创建星辰
            addDirLight(scene) //创建光源
            //监听画布双击事件
            document.getElementById('canvas') &&
                // @ts-ignore
                document.getElementById('canvas').addEventListener('click', handleDblclick, false)

            // const directionalLight = new THREE.DirectionalLight(0xffffff, 20)
            // directionalLight.position.set(6, 2, -6) //default; light shining from top
            // directionalLight.castShadow = true // default false
            // scene.add(directionalLight)
            // const helper = new THREE.CameraHelper(directionalLight.shadow.camera)
            // scene.add(helper)

            planetData.forEach((e) => {
                createSphere(e, scene, mp2)
                createTrack(e, scene)
            })
            orbitControls = createOrbitControls(camera, renderer)
            const canvasDom = document.getElementById('canvas')

            canvasDom?.appendChild(renderer.domElement)
        }
        const loadMat2 = (d) => {
            const tt = textureLoader.load(
                d.mapImg,
                // onLoad callback
                function (texture) {
                    mp2Over[d.name] = 1
                },
                // onProgress callback currently not supported
                function (progree) {
                    console.log(progree)
                },
                // onError callback
                function () {
                    console.error('An error happened.')
                },
            )
            return tt
        }

        const loadEarthMaterial = async () => {
            const loadImg = (name, mapImg) => {
                mp2Over[name] = 0
                mp2[name] = loadMat2({ mapImg, name })
            }

            loadImg('universeImg', './img/universe.jpg')

            planetData.forEach((d) => {
                mp2Over[d.name] = 0
                mp2[d.name] = loadMat2(d)
            })
            const keys = Object.keys(mp2Over)
            function st() {
                const f = keys.filter((key) => mp2Over[key] == 0)
                if (f.length == 0) {
                    setTimeout(() => {
                        // @ts-ignore
                        document.getElementById('loading').style.display = 'none'
                        init()
                        animate()
                    }, 1)
                } else {
                    requestAnimationFrame(st) //记录下动画id可用于销毁场景
                }
            }
            st()
        }
        loadEarthMaterial()
        return () => {
            document.getElementById('canvas') &&
                // @ts-ignore
                document.getElementById('canvas').removeEventListener('click', handleDblclick, false)
        }
    }, [])

    const handleCancel = () => {
        setIsModalOpen(false)
        config.mask = false
    }

    const sp = window.s[_html]
    return (
        <div className={styles.main} id="main">
            <div id="canvas" />
            <div id="loading" className={styles.progress} style={{ top: innerHeight / 2 }}>
                <div className={styles.loading}>Loading</div>
            </div>
            {isModalOpen && (
                <div className={styles.md}>
                    <div className={styles.mdb} />
                    <div className={styles.tbg}>
                        <div className={styles.close} onClick={handleCancel}>
                            ×
                        </div>
                        <div className={styles.tinline}>
                            <Swiper
                                // spaceBetween={50}
                                // slidesPerView={1}
                                modules={[Navigation, Pagination]}
                                navigation
                                pagination={{ clickable: true }}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                className={styles.swiper}
                            >
                                {sp.map((d) => {
                                    return (
                                        <SwiperSlide key={d.src} className={styles.slide}>
                                            {d.type == 'image' && <img src={d.src} alt="" className={styles.slidei} />}
                                            {d.type == 'video' && (
                                                <video
                                                    src={d.src}
                                                    controls // @ts-ignore
                                                    webkit-playsinline="true"
                                                    playsInline={true}
                                                    className={styles.slidei}
                                                />
                                            )}
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                    <video
                        muted
                        autoPlay
                        // @ts-ignore
                        webkit-playsinline="true"
                        playsInline={true}
                        className={`${styles.tbg} ${styles.tvideo}`}
                        src="/tb.mp4"
                    />
                </div>
            )}
        </div>
    )
}

export default App
