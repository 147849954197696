import dayjs from 'dayjs'
import React from 'react'

import styles from './styles.module.less'

export const Modal1 = ({ tableData1 }) => {
    return (
        <div className={styles.modal1}>
            <table className={styles.table2} border={0} cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr>
                        <td>业务编号</td>
                        <td>城市地区</td>
                        <td>程序</td>
                        <td>状态</td>
                        <td>审核人</td>
                        <td>时间</td>
                    </tr>
                </thead>
                <tbody>
                    {tableData1.map((d, i) => {
                        return (
                            <tr key={i}>
                                <td>{d['业务编号']}</td>
                                <td>{d['城市地区']}</td>
                                <td>{d['程序']}</td>
                                <td>{d['状态']}</td>
                                <td>{d['审核人']}</td>
                                <td>{dayjs(d['时间']).format('HH:mm')}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
