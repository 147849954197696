import React from 'react'

import { Tw } from '../Tw'

import { Charts } from './Chart'
import { initChart11 } from './initChart11'
import { initChart12 } from './initChart12'
import styles from './styles.module.less'

export const Charts11 = ({ config }) => {
    const options1 = initChart12(config)
    const options2 = initChart11(config?.option2)
    return (
        <div className={styles.chart11}>
            <div className={styles.c0}>
                <div className={styles.c1}>
                    <Charts options={options1} />
                    <div className={styles.m2}>
                        <div className={styles.m20}>总资金</div>
                        <div className={styles.text + ' ' + styles.m21}>
                            <Tw value={config.data1} dot={4} />
                        </div>
                        <div className={styles.m22}>亿</div>
                    </div>
                </div>
                <div className={styles.c2}>
                    <div className={styles.c2m}>
                        <div className={styles.m}>
                            <div className={styles.m01} />
                            <div className={styles.m1}>自有资金</div>
                            <div className={styles.m2}>
                                <Tw value={config.data2} dot={4} />
                            </div>
                            <div className={styles.m3}>亿</div>
                        </div>
                        <div className={styles.m}>
                            <div className={styles.m02} />
                            <div className={styles.m1}>固收资金</div>
                            <div className={styles.m2}>
                                <Tw value={config.data3} dot={4} />
                            </div>
                            <div className={styles.m3}>亿</div>
                        </div>
                        <div className={styles.m}>
                            <div className={styles.m03} />
                            <div className={styles.m1}>事业合伙人</div>
                            <div className={styles.m2}>
                                <Tw value={config.data4} dot={4} />
                            </div>
                            <div className={styles.m3}>亿</div>
                        </div>
                    </div>
                    <div className={styles.c2m2}>
                        <div className={styles.m}>
                            <div className={styles.m1}>本日新增</div>
                            <div className={styles.m2}>
                                <Tw value={config.data5} dot={4} />
                            </div>
                            <div className={styles.m3}>万</div>
                        </div>
                        <div className={styles.m}>
                            <div className={styles.m1}>本月新增</div>
                            <div className={styles.m2}>
                                <Tw value={config.data6} />
                            </div>
                            <div className={styles.m3}>万</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 160 }}>
                <Charts options={options2} />
            </div>
        </div>
    )
}
