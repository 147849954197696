export const initChart11 = (config) => {
    return {
        legend: {
            show: false,
        },
        color: ['#03b3af', '#005162', '#5ec9e0', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['75%', '100%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 0,
                    borderColor: 'rgba(0,0,0,.5)',
                    borderWidth: 0,
                },
                label: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 20,
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: config,
            },
        ],
    }
}
