
export const menu1 = [
    {
        title: '总资金',
        value: 1,
    },
    {
        title: '当日余额总数',
        value: 2,
    },
]

export const menu2 = [
    {
        title: '总收益',
        value: 1,
    },
    {
        title: '空转率',
        value: 2,
    },
    // {
    //     title: '资贷比',
    //     value: 3,
    // },
]
