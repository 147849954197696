import React from 'react'

import styles from './center.module.less'

const Page = ({ title, num, unit = '亿' }) => {
    return (
        <div className={styles.c1}>
            <div className={styles.c10}>{title}</div>
            <div className={styles.c11}>
                <div className={styles.text + ' ' + styles.c12}>{num}</div>
                <div className={styles.c13}>{unit}</div>
            </div>
        </div>
    )
}

export default Page
