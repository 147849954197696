import React, { useEffect } from 'react'
import Swiper from 'swiper'
import { Autoplay, Pagination, Virtual } from 'swiper/modules'

import 'swiper/less'
import 'swiper/less/pagination'
import 'swiper/less/autoplay'

import styles from './styles.module.less'

export const Modal2 = ({ data }) => {
    useEffect(() => {
        new Swiper('.swiper1', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Virtual],
            direction: 'vertical',
            loop: true,
            autoplay: true,
            slidesPerView: 14,
            spaceBetween: 0,
        })
        new Swiper('.swiper2', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Virtual],
            direction: 'vertical',
            loop: true,
            autoplay: true,
            slidesPerView: 14,
            spaceBetween: 0,
        })
        new Swiper('.swiper3', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Virtual],
            direction: 'vertical',
            loop: true,
            autoplay: true,
            slidesPerView: 14,
            spaceBetween: 0,
        })
    }, [data])
    return (
        <div className={styles.modal2}>
            <div className={styles.outer}>
                <div className={styles.rt1}>城市合伙人</div>
                <div className={styles.table}>
                    <div className={styles.thead}>
                        <div className={styles.d1}>公司名称</div>
                        <div className={styles.d2}>额度</div>
                    </div>
                    <div className={`swiper swiper1 ${styles.swiper}`}>
                        <div className="swiper-wrapper">
                            {data.db1.map((d, i) => {
                                return (
                                    <div key={i} className={`swiper-slide ${styles.slide}`}>
                                        <div className={styles.d1}>{d['公司名称']}</div>
                                        <div className={styles.d2}>{d['额度（额度：万元）']}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.outer}>
                <div className={styles.rt1}>事业合伙人</div>
                <div className={styles.table}>
                    <div className={styles.thead}>
                        <div className={styles.d1}>名称</div>
                        <div className={styles.d2}>额度</div>
                    </div>
                    <div className={`swiper swiper2 ${styles.swiper}`}>
                        <div className="swiper-wrapper">
                            {data.db2.map((d, i) => {
                                return (
                                    <div key={i} className={`swiper-slide ${styles.slide}`}>
                                        <div className={styles.d1}>{d['名称']}</div>
                                        <div className={styles.d2}>{d['额度（额度：万元）']}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.outer}>
                <div className={styles.rt1}>意向合伙人</div>
                <div className={styles.table}>
                    <div className={styles.thead}>
                        <div className={styles.d1}>名称</div>
                        <div className={styles.d2}>额度</div>
                    </div>
                    <div className={`swiper swiper3 ${styles.swiper}`}>
                        <div className="swiper-wrapper">
                            {data.db3.map((d, i) => {
                                return (
                                    <div key={i} className={`swiper-slide ${styles.slide}`}>
                                        <div className={styles.d1}>{d['名称']}</div>
                                        <div className={styles.d2}>{d['额度（额度：万元）']}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
