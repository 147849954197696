import * as echarts from 'echarts'
import React, { useEffect, useRef, useState } from 'react'

import Content from '../Components/Content'
import Menu, { Menu2 } from '../Components/Menu'
import Title from '../Components/Title'

export const Charts = ({ options }) => {
    const chartRef = useRef<any>()

    useEffect(() => {
        if (!options) {
            return
        }
        const chart = echarts.init(chartRef.current)
        chart.setOption(options)
    }, [options])
    return <div style={{ height: '100%' }} ref={chartRef} />
}

const ChartOuter = ({ data, setData, title, list, config, children }) => {
    const { step, step2 } = data
    const [visible, setVisible] = useState(false)
    const obj = list.reduce((obj, item) => {
        return { ...obj, [item.value]: item }
    }, {})

    useEffect(() => {
        // @ts-ignore
        const handleC = () => setVisible(false)
        window.addEventListener('click', handleC, false)
        return () => {
            window.removeEventListener('click', handleC, false)
        }
    }, [])

    const handleClick = (e) => {
        e.stopPropagation()
        setVisible(true)
    }

    const handleChangeStep1 = (step) => {
        const step2 = obj[step].children ? obj[step].children[0].value : 0
        setData({ step, step2 })
    }
    const handleChangeStep2 = (step2) => {
        setData({ step, step2 })
    }
    const list2 = obj[step].children

    return (
        <Content
            title={title}
            right={
                <div>
                    <Title onClick={handleClick}>{obj[step].title}</Title>
                    {visible && <Menu list={list} value={step} onChange={handleChangeStep1} />}
                </div>
            }
        >
            {list2 && <Menu2 list={list2} value={step2} onChange={handleChangeStep2} />}
            <div style={{ height: !!list2 ? '250px' : '290px' }}>
                {children}
                {/* {config && <Charts key={`${step}_${step2}`} options={config} />} */}
            </div>
        </Content>
    )
}

export default ChartOuter
