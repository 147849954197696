// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module_modal1-R_wSR {\n  flex: 1;\n  overflow-y: auto;\n  height: 580px;\n}\n.styles-module_table2-Yi_aS {\n  width: 100%;\n  margin: 10px 0 0;\n  padding: 0 0 2px;\n}\n.styles-module_table2-Yi_aS tr {\n  font-size: 16px;\n  line-height: 35px;\n}\n.styles-module_table2-Yi_aS td {\n  padding: 0 10px;\n}\n.styles-module_table2-Yi_aS thead tr {\n  color: #0fbcbb;\n  font-size: 16px;\n}\n.styles-module_table2-Yi_aS tbody td {\n  color: #b4dcdc;\n  border-bottom: 1px solid #608688;\n}\n.styles-module_table2-Yi_aS tbody tr:nth-last-child(1) td {\n  border-bottom: none;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Page2/Modal1/styles.module.less"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAEA;EACI,WAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAHA;EAMQ,eAAA;EACA,iBAAA;AAAR;AAPA;EAWQ,eAAA;AADR;AAVA;EAeQ,cAAA;EACA,eAAA;AAFR;AAdA;EAoBQ,cAAA;EACA,gCAAA;AAHR;AAlBA;EAyBQ,mBAAA;AAJR","sourcesContent":[".modal1 {\n    flex: 1;\n    overflow-y: auto;\n    height: 580px;\n}\n\n.table2 {\n    width: 100%;\n    margin: 10px 0 0;\n    padding: 0 0 2px;\n\n    tr {\n        font-size: 16px;\n        line-height: 35px;\n    }\n\n    td {\n        padding: 0 10px;\n    }\n\n    thead tr {\n        color: #0fbcbb;\n        font-size: 16px;\n    }\n\n    tbody td {\n        color: #b4dcdc;\n        border-bottom: 1px solid #608688;\n    }\n\n    tbody tr:nth-last-child(1) td {\n        border-bottom: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal1": "styles-module_modal1-R_wSR",
	"table2": "styles-module_table2-Yi_aS"
};
export default ___CSS_LOADER_EXPORT___;
