import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import useData from '@/utils/useData'

import { Chart1, Chart2 } from './Charts'
import Center from './Components/Center'
import Content from './Components/Content'
import Map from './Components/Map'
import { M1, M2 } from './Components/Right'
import Table from './Components/Table'
import TimeData, { Weather } from './Components/TimeData'
import arrow2 from './images/arrow2.png'
import Modal from './Modal'
import { Modal1 } from './Modal1'
import { Modal2 } from './Modal2'
import { Modal3 } from './Modal3'
import { Modal4 } from './Modal4'
import styles from './styles.module.less'
import { Tw } from './Tw'

const Page = () => {
    const [data, setData] = useData({
        visible1: false,
        tableData1: [],
        tableData2: [],
        visible2: false,
        visible3: false,
        visible4: false,
    })

    const { visible1, visible2, visible3, visible4, tableData1, tableData2 } = data
    useEffect(() => {
        const getData = async () => {
            let r = await fetch('./data/data.json').then((r) => r.json())
            console.log(r)
            setData(r.data)
        }
        const getData1 = async () => {
            let tableData1 = await fetch('./data/table.json').then((r) => r.json())
            setData({ tableData1 })
        }
        const getData2 = async () => {
            let tableData2 = await fetch('./data/hzjg.json').then((r) => r.json())
            setData({ tableData2 })
        }

        getData()
        getData1()
        getData2()
    }, [])
    return (
        <div className={styles.main} id="main">
            <video
                muted
                loop
                autoPlay
                playsInline={true} // @ts-ignore
                webkit-playsinline="true"
                className={styles.video}
                id="video"
                src="/2.mp4"
            />
            <div className={styles.text + ' ' + styles.title}>闪垫金服数据监测平台</div>
            <Map />
            <TimeData />
            <Weather />
            <Link to="/" className={styles.back} />
            <div className={styles.center}>
                <Center title="总放款量" num={<Tw value={data.getIn(['center', 'total'])} max={6420} />} />
                <Center title="总笔数" unit="笔" num={<Tw value={data.getIn(['center', 'totalNum'])} max={3256} />} />
                <Center title="总营收" num={<Tw dot={2} value={data.getIn(['center', 'totalRevenue'])} max={360} />} />
            </div>
            <div className={styles.left}>
                <Chart1 data={data.chart1} />
                <Chart2 data={data.chart2} />
                <Table list={tableData1} setData={setData} />
            </div>
            <div className={styles.right}>
                <Content title="业务总览">
                    <div className={styles.d}>
                        <M1
                            title="今日放款笔数"
                            unit="笔"
                            num={<Tw value={data.getIn(['right1', 'dayLending'])} max={10000} />}
                        />
                        <M1
                            title="今日放款额"
                            unit="万"
                            num={<Tw value={data.getIn(['right1', 'dayTotalMoney'])} max={10000} />}
                        />
                        <M1
                            title="本月总笔数"
                            unit="笔"
                            num={<Tw value={data.getIn(['right1', 'monthLending'])} max={10000} />}
                        />
                        <M1
                            title="本月放款"
                            num={<Tw dot={4} value={data.getIn(['right1', 'monthOnMoney'])} max={10000} />}
                        />
                        <M1
                            title="在途总笔数"
                            unit="笔"
                            num={<Tw value={data.getIn(['right1', 'onLending'])} max={10000} />}
                        />
                        <M1
                            title="在途资金总量"
                            num={<Tw dot={4} value={data.getIn(['right1', 'onTotalMoney'])} max={10000} />}
                        />
                        <M1
                            title="意向额度"
                            unit="万"
                            num={<Tw value={data.getIn(['right1', 'intentionMoney'])} max={10000} />}
                        />
                        <M1
                            title="点击查看"
                            num="业均值"
                            onClick={() => setData({ visible4: true })}
                            style={{ cursor: 'pointer' }}
                            // @ts-ignore
                            unit={<img src={arrow2} />}
                        />
                    </div>
                </Content>
                <Content title="贷后实况" style={{ padding: '0 0 10px' }}>
                    <div className={styles.d3} />
                    <div className={styles.d}>
                        <M2
                            title="延期（A）"
                            unit="笔"
                            num={<Tw value={data.getIn(['right2', 'delay'])} max={100} />}
                        />
                        <M2
                            title="逾期（B）"
                            unit="笔"
                            num={<Tw value={data.getIn(['right2', 'overdue'])} max={100} />}
                        />
                        <M2
                            title="不良（C）"
                            unit="笔"
                            num={<Tw value={data.getIn(['right2', 'bad'])} max={10000} />}
                        />
                    </div>
                    <div className={styles.d2} onClick={() => setData({ visible3: true })}>
                        {/* <M3 title="总逾期" num={<Tw value={data.getIn(['right2', 'totalDelay'])} max={10000} />} />
                        <M3 title="处置结案" num={<Tw value={data.getIn(['right2', 'over'])} max={10000} />} />
                        <M3 title="本息盈亏" num={<Tw value={data.getIn(['right2', 'bep'])} max={10000} />} /> */}
                    </div>
                </Content>
                <div className={styles.c2} onClick={() => setData({ visible2: true })} />
            </div>
            {visible1 && (
                <Modal title="审批实况" setData={() => setData({ visible1: false })}>
                    <Modal1 tableData1={tableData1} />
                </Modal>
            )}
            {visible2 && (
                <Modal title="合作机构" setData={() => setData({ visible2: false })}>
                    <Modal2 data={tableData2} />
                </Modal>
            )}
            {visible3 && (
                <Modal title="历史总览" setData={() => setData({ visible3: false })}>
                    <Modal3 data={data.chart3} />
                </Modal>
            )}
            {visible4 && (
                <Modal title="业均值" setData={() => setData({ visible4: false })}>
                    <Modal4 data={data.chart4} />
                </Modal>
            )}
        </div>
    )
}

export default Page
