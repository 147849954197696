import React from 'react'

import styles from './menu.module.less'

const Menu = ({ list, value, onChange }) => {
    return (
        <div className={styles.t11}>
            {list.map((d) => {
                return (
                    <div
                        key={d.value}
                        onClick={() => onChange(d.value)}
                        className={d.value == value ? styles.t11_t + ' ' + styles.t11_h : styles.t11_t}
                    >
                        {d.title}
                    </div>
                )
            })}
        </div>
    )
}

Menu.defaultProps = {
    list: [],
    value: '',
}

export const Menu2 = ({ list, value, onChange }) => {
    return (
        <div className={styles.t2}>
            {list.map((d) => {
                return (
                    <div
                        key={d.value}
                        onClick={() => onChange(d.value)}
                        className={d.value == value ? styles.t2_t + ' ' + styles.t2_h : styles.t2_t}
                    >
                        {d.title}
                    </div>
                )
            })}
        </div>
    )
}

Menu2.defaultProps = {
    list: [],
    value: '',
}

export default Menu
