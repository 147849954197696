import React from 'react'

import { Tw } from '../Tw'

import styles from './styles.module.less'

export const Charts12 = ({ config }) => {
    return (
        <div className={styles.chart12}>
            <div className={styles.c11}>
                <div className={styles.text + ' ' + styles.c12}>
                    <Tw value={config} />
                </div>
                <div className={styles.c13}>万</div>
            </div>
        </div>
    )
}
