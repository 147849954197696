// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/registry.npmmirror.com+css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/time.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/loc.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeData-module_time-V3kcO {\n  position: absolute;\n  left: 25px;\n  top: 70px;\n  color: #fff;\n  font-size: 18px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center left;\n  background-size: 22px 18px;\n  padding: 0 0 0 40px;\n}\n.timeData-module_week-TaW2E {\n  color: #0fbab9;\n  margin: 0 15px;\n}\n.timeData-module_weather-Z3w7S {\n  position: absolute;\n  display: flex;\n  right: 100px;\n  top: 70px;\n  color: #fff;\n  font-size: 18px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center left;\n  background-size: 17px 22px;\n  padding: 0 0 0 30px;\n}\n.timeData-module_icon-Az_EN {\n  display: block;\n  width: 24px;\n  height: auto;\n  margin: 0 15px 0 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Page2/Components/timeData.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,yEAAA;EACA,0BAAA;EACA,mBAAA;AACJ;AAEA;EACI,cAAA;EACA,cAAA;AAAJ;AAGA;EACI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,yEAAA;EACA,0BAAA;EACA,mBAAA;AADJ;AAKA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;AAHJ","sourcesContent":[".time {\n    position: absolute;\n    left: 25px;\n    top: 70px;\n    color: #fff;\n    font-size: 18px;\n    background: url(\"../images/time.png\") no-repeat center left;\n    background-size: 22px 18px;\n    padding: 0 0 0 40px;\n}\n\n.week {\n    color: #0fbab9;\n    margin: 0 15px;\n}\n\n.weather {\n    position: absolute;\n    display: flex;\n    right: 100px;\n    top: 70px;\n    color: #fff;\n    font-size: 18px;\n    background: url(\"../images/loc.png\") no-repeat center left;\n    background-size: 17px 22px;\n    padding: 0 0 0 30px;\n    // transform: translateX(100%);\n}\n\n.icon {\n    display: block;\n    width: 24px;\n    height: auto;\n    margin: 0 15px 0 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": "timeData-module_time-V3kcO",
	"week": "timeData-module_week-TaW2E",
	"weather": "timeData-module_weather-Z3w7S",
	"icon": "timeData-module_icon-Az_EN"
};
export default ___CSS_LOADER_EXPORT___;
