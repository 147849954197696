//创建太阳
import * as THREE from 'three'

export const createSun = (data, scene, mp2) => {
    let sunGroup = new THREE.Group(); //太阳的组
    let sunGeometry = new THREE.SphereGeometry(data.size, 100, 100); //太阳几何体
    let sunMaterial = new THREE.MeshLambertMaterial({
        //太阳材质
        color: 0xffffff,
        // map: data.texture,
        map: mp2[data.name],
        transparent: true
        // map: new THREE.TextureLoader().load(data.mapImg),
    });
    sunMaterial.map.colorSpace = THREE.SRGBColorSpace
    // material.color.set('#ddd')

    let sunMesh = new THREE.Mesh(sunGeometry, sunMaterial);
    sunGroup.add(sunMesh);
    //太阳大气几何体
    let sunAtmosphereGeometry = new THREE.SphereGeometry(
        data.size + 8,
        100,
        100
    );
    let sunAtmosphereMaterial = new THREE.MeshLambertMaterial({
        color: new THREE.Color(0xffffff),
        transparent: true,
        opacity: 0.2,
    });
    let sunAtmosphereMesh = new THREE.Mesh(
        sunAtmosphereGeometry,
        sunAtmosphereMaterial
    );
    sunGroup.add(sunAtmosphereMesh);
    sunGroup.name = data.name; //网格名字
    sunGroup.planetMsg = data;
    sunGroup.isPlanet = true; //标识为星球
    sunGroup.angle = 0; //添加初始角度
    //球体位置
    sunGroup.position.set(
        data.position[0],
        data.position[1],
        data.position[2]
    );
    scene.add(sunGroup)
}
