import React from 'react'

import styles from './right.module.less'

export const M1 = ({ title, num, unit = '亿', ...rest }) => {
    return (
        <div className={styles.m1} {...rest}>
            <div className={styles.m10}>{title}</div>
            <div className={styles.text + ' ' + styles.m11}>
                <div className={styles.m12}>{num}</div>
                <div className={styles.m13}>{unit}</div>
            </div>
        </div>
    )
}

export const M2 = ({ title, num, unit = '亿' }) => {
    return (
        <div className={styles.m2}>
            <div className={styles.m20}>{title}</div>
            <div className={styles.text + ' ' + styles.m21}>{num}</div>
            <div className={styles.m22}>{unit}</div>
        </div>
    )
}

export const M3 = ({ title, num }) => {
    return (
        <div className={styles.m3}>
            <div className={styles.text + ' ' + styles.m31}>{num}</div>
            <div className={styles.m32}>{title}</div>
        </div>
    )
}
