
// 创建宇宙(球形宇宙)
import * as THREE from 'three'

export const createUniverse = (scene, mp2) => {
    let universeGeometry = new THREE.SphereGeometry(7000, 100, 100);
    let universeMaterial = new THREE.MeshLambertMaterial({
        //高光材质
        map: mp2.universeImg,
        // map: new THREE.TextureLoader().load(universeImg),
        side: THREE.DoubleSide, //双面显示
    });
    //宇宙网格
    let universeMesh = new THREE.Mesh(universeGeometry, universeMaterial);
    universeMesh.name = "宇宙";
    scene.add(universeMesh);
}