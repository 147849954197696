import * as echarts from "echarts";

export const config1 = {
    // title: {
    //     text: 'ECharts 入门示例'
    // },
    grid: {
        top: 20,
        left: 10,
        right: 10,
        bottom: 10,
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            // boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月'],
            axisTick: {
                alignWithLabel: true,
            },
            axisLine: {
                lineStyle: {
                    color: "#65c6e7"
                }
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            axisLine: {
                show: false,
                lineStyle: {
                    color: "#65c6e7",
                    width: 1,
                    opacity: 1,
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#144070'
                }
            }
        }
    ],
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'line',
            label: {
                backgroundColor: '#f00'
            },
            axis: "x",
            snap: true,
            lineStyle: {
                // color: "#fff",
                width: 1,
                // type: 'solid',
            }
        },
    },
    // legend: {
    //     data: ['销量', 'Line 1', '销量2']
    // },
    series: [
        // {
        //     name: '销量',
        //     type: 'bar',
        //     data: [5, 20, 36, 10, 10, 20]
        // },
        {
            // name: 'Line 1',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
                color: '#0ebcba',
                width: 2
            },
            itemStyle: {
                color: "#24d7ea",
                borderColor: '#000',
                borderWidth: 2,
                borderType: 'solid',
                borderCap: "square",
                opacity: 1
            },
            // showSymbol: false,
            // showAllSymbol: true,
            areaStyle: {
                opacity: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgba(14, 188, 186,0.5)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(14, 188, 186,0.1)'
                    }
                ])
            },
            // visualMap: [
            //     {
            //         show: false,
            //         type: 'continuous',
            //         seriesIndex: 0,
            //         min: 0,
            //         max: 400
            //     },
            // ],
            emphasis: {
                focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250]
        },
    ]
}
