import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import w1 from '../images/w1.png'
import useData from '@/utils/useData'

import styles from './timeData.module.less'

const url = 'https://restapi.amap.com/v3/'
const key = '5f9a347c0d9bb3de2f204e3ac75b4e58'

const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

const ws = {
    晴: w1,
}

const TimeData = () => {
    const [state, _setState] = useState({ ymd: '2023.11.01', week: '星期一', time: '00:00:00' })
    const { ymd, week, time } = state
    useEffect(() => {
        const getTime = () => {
            const d = dayjs()
            let ymd = d.format('YYYY.MM.DD')
            let week = weeks[d.format('d')]
            let time = d.format('HH:mm:ss')
            _setState({ ymd, week, time })
            requestAnimationFrame(getTime)
        }
        getTime()
    }, [])
    return (
        <div className={styles.time}>
            <span>{ymd}</span>
            <span className={styles.week}>{week}</span>
            <span>{time}</span>
        </div>
    )
}

export default TimeData

export const Weather = () => {
    const [state, setState] = useData({ cityName: '北京市', adcode: undefined, weather: '晴', temperature: 0 })
    useEffect(() => {
        const getWeather = async (city) => {
            let r = await fetch(`${url}weather/weatherInfo?key=${key}&city=${city}&extensions=base`).then((r) =>
                r.json(),
            )
            if (r.infocode == 10000) {
                const wt = r.lives[0]
                if (wt) {
                    console.log(wt)
                    setState({ weather: wt.weather, temperature: wt.temperature })
                }
            }
        }
        const getCity = async () => {
            let r = await fetch(`${url}ip?key=${key}`).then((r) => r.json())
            const { infocode, province, city, adcode } = r
            if (infocode == 10000) {
                let cityName = province == city ? province : `${province}-${city}`
                setState({ cityName, adcode })
                getWeather(adcode)
            }
        }
        getCity()
    }, [])
    return (
        <div className={styles.weather}>
            <span>{state.cityName}</span>
            <img className={styles.icon} src={ws['晴']} alt="" />
            <span className={styles.w}>{state.weather}</span>
            <span className={styles.week}>{state.temperature}℃</span>
        </div>
    )
}
