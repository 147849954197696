import React from 'react'

import useData from '@/utils/useData'

import Chart from './Chart'
import { Charts11 } from './Charts11'
import { Charts12 } from './Charts12'
import { Charts21 } from './Charts21'
import { Charts22 } from './Charts22'
import { config1 } from './config1'
import { config2 } from './config2'
import { menu1, menu2 } from './menuList'

const config = {
    1: config1,
    2: config2,
}

export const Chart1 = ({ data }) => {
    const [state, setState] = useData({ step: 1, step2: 0 })
    const { step } = state

    return (
        <Chart title="财资总览" list={menu1} config={config} data={state} setData={setState}>
            {step == 1 && data?.chart11 && <Charts11 config={data?.chart11} />}
            {step == 2 && data?.chart12 && <Charts12 config={data?.chart12} />}
        </Chart>
    )
}

export const Chart2 = ({ data }) => {
    const [state, setState] = useData({ step: 1, step2: 1 })
    const { step } = state

    return (
        <Chart title="财务数据" list={menu2} config={config[step]} data={state} setData={setState}>
            {step == 1 && data?.chart21 && <Charts21 config={data?.chart21} />}
            {step == 2 && data?.chart22 && <Charts22 config={data?.chart22} />}
        </Chart>
    )
}
