import React from 'react'

import { Tw } from '../Tw'

import { Charts } from './Chart'
import { initChart11 } from './initChart11'
import styles from './styles.module.less'

export const Charts22 = ({ config }) => {
    const options2 = initChart11(config?.option2)
    return (
        <div className={styles.chart21}>
            <div className={styles.c0}>
                <div className={styles.m}>
                    <div className={styles.m1}>本年空转率</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={config.year1} dot={2} />
                        </div>
                        <div className={styles.m22}>%</div>
                    </div>
                    <div className={styles.m3}>
                        <div className={styles.m31}>资贷比</div>
                        <div className={styles.m32}>1:1</div>
                    </div>
                </div>
                <div className={styles.m}>
                    <div className={styles.m1}>本月空转率</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={config.month1} dot={2} />
                        </div>
                        <div className={styles.m22}>%</div>
                    </div>
                    <div className={styles.m3}>
                        <div className={styles.m31}>资贷比</div>
                        <div className={styles.m32}>1:1</div>
                    </div>
                </div>
                <div className={styles.m}>
                    <div className={styles.m1}>本日空转率</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={config.day1} dot={2} />
                        </div>
                        <div className={styles.m22}>%</div>
                    </div>
                    <div className={styles.m3}>
                        <div className={styles.m31}>资贷比</div>
                        <div className={styles.m32}>1:1</div>
                    </div>
                </div>
            </div>
            <div style={{ height: 190 }}>
                <Charts options={options2} />
            </div>
        </div>
    )
}
