//创建地球
import * as THREE from 'three'

import earthCloudsImg from "../img/earthClouds.jpg"; //地球云层
import earthNormalImg from "../img/earthNormal.jpg"; //法线贴图
import moonImg from "../img/moon.jpg"; //月球

export const createEarth = (data, scene, mp2) => {
    let earthGroup = new THREE.Group(); //地球的组
    let earthGeometry = new THREE.SphereGeometry(data.size, 100, 100); //地球几何体
    //地球材质
    let earthMaterial = new THREE.MeshPhysicalMaterial({
        // map: new THREE.TextureLoader().load(data.mapImg),
        // map: data.texture,
        map: mp2[data.name],
        normalScale: new THREE.Vector2(10, 10), //凹凸深度
        normalMap: new THREE.TextureLoader().load(earthNormalImg), //法线贴图
    });
    let earthMesh = new THREE.Mesh(earthGeometry, earthMaterial); //地球网格
    earthGroup.add(earthMesh); //将地球网格添加到地球组中
    //地球云层几何体
    let earthCloudsGeometry = new THREE.SphereGeometry(
        data.size + 2,
        100,
        100
    );
    //地球云层材质
    let earthCloudsMaterial = new THREE.MeshLambertMaterial({
        color: new THREE.Color(0xffffff),
        transparent: true,
        opacity: 0.4,
        map: new THREE.TextureLoader().load(earthCloudsImg),
    });
    //地球云层网格
    let earthCloudsMesh = new THREE.Mesh(
        earthCloudsGeometry,
        earthCloudsMaterial
    );
    earthGroup.add(earthCloudsMesh); //将地球云层网格添加到地球组中

    //创建月球轨道
    let moonTrackGeometry = new THREE.RingGeometry( //圆环几何体
        data.size + 40,
        data.size + 40.2,
        100
    );
    let moonTrackMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
    });
    let moonTrackMesh = new THREE.Mesh(moonTrackGeometry, moonTrackMaterial);
    moonTrackMesh.rotation.set(0.5 * Math.PI, 0, 0);
    earthGroup.add(moonTrackMesh);

    //创建月球
    let moonGeometry = new THREE.SphereGeometry(10, 100, 100);
    let moonMaterial = new THREE.MeshPhysicalMaterial({
        map: new THREE.TextureLoader().load(moonImg),
        normalScale: new THREE.Vector2(10, 10), //凹凸深度
    });
    let moonMesh = new THREE.Mesh(moonGeometry, moonMaterial);
    moonMesh.position.set(data.size + 40, 0, 0);
    earthGroup.add(moonMesh);

    earthGroup.name = data.name; //网格名字
    earthGroup.planetMsg = data;
    earthGroup.isPlanet = true; //标识为星球
    earthGroup.angle = 0; //添加初始角度
    //球体位置
    earthGroup.position.set(
        data.position[0],
        data.position[1],
        data.position[2]
    );
    scene.add(earthGroup);
}