import React from 'react'

import { Charts } from '../Charts/Chart'
import { Tw } from '../Tw'

import i5 from './images/5.png'
import i6 from './images/6.png'
import { initChart11 } from './initChart11'
import styles from './styles.module.less'

export const Modal3 = ({ data = {} }: any) => {
    const options1 = initChart11([
        { value: data.data5, name: '总本追回' },
        { value: 100 - data.data5, name: '自有资金' },
    ])
    const options2 = initChart11([
        { value: data.data7, name: '总息追回' },
        { value: 100 - data.data7, name: '自有资金' },
    ])
    return (
        <div className={styles.modal3}>
            <div className={styles.c1}>
                <div className={styles.m}>
                    <div className={styles.m1}>总逾期额</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={data.data1} />
                        </div>
                        <div className={styles.m22}>万</div>
                    </div>
                    <div className={styles.m3}>
                        <div className={styles.m31}>总逾期</div>
                        <div className={styles.m32}>
                            <Tw value={data.data2} />
                        </div>
                        <div className={styles.m33}>笔</div>
                    </div>
                </div>
                <div className={styles.m0}>
                    <div className={styles.m1}>总诉讼额</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={data.data3} />
                        </div>
                        <div className={styles.m22}>万</div>
                    </div>
                    <div className={styles.m3}>
                        <div className={styles.m31}>总诉讼</div>
                        <div className={styles.m32}>
                            <Tw value={data.data4} />
                        </div>
                        <div className={styles.m33}>笔</div>
                    </div>
                </div>
            </div>
            <div className={styles.c2}>
                <div className={styles.m01}>
                    <div className={styles.chart}>
                        <Charts options={options1} />
                    </div>
                    <div className={styles.m1}>总本追回</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={data.data5} dot={1} />
                        </div>
                        <div className={styles.m22}>%</div>
                    </div>
                    <img src={i5} className={styles.m3} alt="" />
                </div>
                <div className={styles.m02}>
                    <div className={styles.m1}>总亏</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={data.data6} />
                        </div>
                        <div className={styles.m22}>万</div>
                    </div>
                </div>
                <div className={styles.m03}>
                    <div className={styles.chart}>
                        <Charts options={options2} />
                    </div>
                    <div className={styles.m1}>总息追回</div>
                    <div className={styles.m2}>
                        <div className={styles.m21}>
                            <Tw value={data.data7} dot={2} />
                        </div>
                        <div className={styles.m22}>万</div>
                    </div>
                    <img src={i6} className={styles.m3} alt="" />
                </div>
            </div>
        </div>
    )
}
