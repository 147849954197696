// 创建金星
import * as THREE from 'three'

import venusAtmosphereImg from "../img/venusAtmosphere.jpg"; //金星大气

export const createVenus = (data, scene, mp2) => {
    let venusGroup = new THREE.Group(); //金星的组
    let venusGeometry = new THREE.SphereGeometry(data.size, 100, 100); //金星几何体
    //金星材质
    let venusMaterial = new THREE.MeshLambertMaterial({
        color: new THREE.Color(0xffffff),
        map: mp2[data.name],
        // map: new THREE.TextureLoader().load(data.mapImg),
    });
    let venusMesh = new THREE.Mesh(venusGeometry, venusMaterial);
    venusGroup.add(venusMesh);
    //金星大气几何体
    let venusAtmosphereGeometry = new THREE.SphereGeometry(
        data.size + 2,
        100,
        100
    );
    //金星大气材质
    let venusAtmosphereMaterial = new THREE.MeshLambertMaterial({
        color: new THREE.Color(0xffffff),
        transparent: true,
        opacity: 0.5,
        map: new THREE.TextureLoader().load(venusAtmosphereImg),
    });
    let venusAtmosphereMesh = new THREE.Mesh(
        venusAtmosphereGeometry,
        venusAtmosphereMaterial
    );
    venusGroup.add(venusAtmosphereMesh); //将大气添加到组中
    venusGroup.name = data.name; //网格名字
    venusGroup.planetMsg = data;
    venusGroup.isPlanet = true; //标识为星球
    venusGroup.angle = 0; //添加初始角度
    //球体位置
    venusGroup.position.set(
        data.position[0],
        data.position[1],
        data.position[2]
    );
    scene.add(venusGroup);
}