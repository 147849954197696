import * as TWEEN from '@tweenjs/tween.js'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as THREE from 'three'

import { rs } from '@/config'
import { bd, bt, map } from '@/images'
import { addDirLight, addObj } from '@/objs'

import { s } from './config'
import styles from './styles.module.less'

import 'swiper/less'
import 'swiper/less/navigation'
import 'swiper/less/pagination'

function isMobile() {
    let userAgentInfo = navigator.userAgent
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    let getArr = Agents.filter((i) => userAgentInfo.includes(i))
    return getArr.length ? true : false
}

const App = () => {
    let config = {
        animationType: false, // 是否显示卫星
        rotationY: true, // 地球自动旋转
        mask: false,
        move: false,
        isDown: false,
    }
    const navgate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [_html, setHtml] = useState('')

    useEffect(() => {
        const EARTH_RADIUS = 70

        const cameraY = 99.33466539753063
        const cameraZ = 490.0332889206208
        let camera
        let renderer
        let earth
        let earthMaterial
        let isOk = false
        // let loadingOk = false
        let isMove = false

        const mainDom: any = document.getElementById('main')
        const rleft = mainDom.getBoundingClientRect().left

        console.log(rleft)

        const group = new THREE.Group()
        group.position.y = 20

        const group2 = new THREE.Group()
        const scene = new THREE.Scene()
        const textureLoader = new THREE.TextureLoader()
        const raycaster = new THREE.Raycaster()
        const pointer = new THREE.Vector2(-1, -1)

        const innerWidth = 1920
        const innerHeight = 1080

        // const innerWidth = 1920
        // const innerHeight = 1080

        const loadEarthMaterial = () => {
            textureLoader.load(
                map,
                // onLoad callback
                function (texture) {
                    earthMaterial = new THREE.MeshBasicMaterial({
                        map: texture,
                    })
                    earthMaterial.map.colorSpace = THREE.SRGBColorSpace
                },
                // onProgress callback currently not supported
                function (progree) {
                    console.log(progree)
                },
                // onError callback
                function () {
                    console.error('An error happened.')
                },
            )
        }

        // @ts-ignore
        const addEarth = () => {
            let geometry = new THREE.SphereGeometry(EARTH_RADIUS, 100, 100) //创建球
            earth = new THREE.Mesh(geometry, earthMaterial) //网格模型对象Mesh
            earth.name = 'earth' // 唯一标识
            earth.privateType = 'earth'
            earth.layers.enableAll()
            group.add(earth)
            // earth.rotation.z = 1

            const textureb = textureLoader.load(bt)
            textureb.encoding = THREE.sRGBEncoding
            // texture.flipY = false;
            const q = new THREE.SpriteMaterial({ map: textureb, transparent: true, opacity: 1 })
            const sprite = new THREE.Sprite(q)
            sprite.scale.set(129, 124, 1)
            sprite.position.set(14, 45, 100)
            sprite.rotation.y = 1
            scene.add(sprite)
            console.log(bd.length)
            console.log(bt.length)

            const texturebd = textureLoader.load(bd)
            texturebd.encoding = THREE.sRGBEncoding
            // texture.flipY = false;
            const q2 = new THREE.SpriteMaterial({ map: texturebd, transparent: true, opacity: 1 })
            const sprite2 = new THREE.Sprite(q2)
            sprite2.scale.set(164, 158, 1)
            sprite2.position.set(10, 26, 0)
            sprite2.rotation.y = 1
            scene.add(sprite2)

            const loading = document.getElementById('loading')
            console.log(loading)
            // @ts-ignore
            loading.style.display = 'none'
        }

        const addMoon = () => {
            config.animationType = true
            rs.map((d) => {
                let g = addObj(d)
                group2.add(g)
            })
            group2.scale.set(0, 0, 1)
            group.add(group2)
            new TWEEN.Tween({ scale: 0 })
                .to({ scale: 1 }, 600)
                .onUpdate(function (r) {
                    const { scale } = r
                    group2.scale.set(scale, scale, 1)
                })
                .onComplete((r) => {
                    group2.scale.set(1, 1, 1)
                })
                .easing(TWEEN.Easing.Sinusoidal.InOut)
                .start()
        }

        const initRenderer = () => {
            renderer = new THREE.WebGLRenderer({ antialias: true })
            // renderer.setPixelRatio(window.devicePixelRatio)
            renderer.setSize(innerWidth, innerHeight)
            renderer.setClearColor(0x000, 0)
        }

        const initCamera = () => {
            camera = new THREE.PerspectiveCamera(45, innerWidth / innerHeight, 1, 1000)
            camera.position.set(0, cameraY, cameraZ)
            camera.lookAt(0, 0, 0)
            camera.layers.enableAll()
        }

        function init() {
            // const { innerWidth, innerHeight } = window
            initCamera()
            initRenderer()

            const canvasDom = document.getElementById('canvas2')
            canvasDom?.appendChild(renderer.domElement)

            const dirLight = addDirLight()
            scene.add(dirLight)
            scene.rotation.z = 0.19
            scene.rotation.x = 0.05
            scene.add(group)
            loadEarthMaterial()
        }

        const handleMove = () => {
            raycaster.setFromCamera(pointer, camera)
            // 计算物体和射线的焦点
            const intersects = raycaster.intersectObjects(scene.children)
            const earth = intersects.find((obj) => obj.object.privateType == 'earth')
            const moons = intersects.filter((obj) => obj.object.privateType == 'column')

            if (moons.length == 0) {
                config.rotationY = true
                return
            }
            if (!earth) {
                config.rotationY = false
                return
            }
            const cr = moons.reduce((obj, item) => {
                if (!obj) {
                    return item
                }
                if (obj.distance > item.distance) {
                    return item
                } else {
                    return obj
                }
            }, undefined)
            if (earth.distance > cr.distance) {
                config.rotationY = false
            } else {
                config.rotationY = true
            }
        }

        function animate() {
            requestAnimationFrame(animate)
            handleMove()
            if (isMove) {
            } else if (config.mask || config.rotationY) {
                group.rotation.y += 0.003
            }
            renderer.render(scene, camera)
            TWEEN.update()
        }

        // const video2: any = document.getElementById('video2')
        // video2.playbackRate = 4
        // video2.play()
        // video2.addEventListener(
        //     'ended',
        //     function () {
        //         //结束
        //         console.log('播放结束2')
        //         // loadingOk = true
        //     },
        //     false,
        // )
        // addEarth()
        const sett = setInterval(() => {
            if (isOk) {
                clearInterval(sett)
            } else if (earthMaterial) {
                isOk = true
                addEarth()
                // @ts-ignore
                // document.getElementById('video').play()
            }
        }, 10)

        const handleClick = () => {
            navgate('/page2')
            return
            if (isMove) {
                return
            }
            // 通过摄像机和鼠标位置更新射线
            raycaster.setFromCamera(pointer, camera)
            // const vv = new THREE.Spherical().setFromVector3(camera.position)
            // 计算物体和射线的焦点
            const intersects = raycaster.intersectObjects(scene.children)
            const earth = intersects.find((obj) => obj.object.privateType == 'earth')
            if (config.animationType == false) {
                if (earth) {
                    addMoon()
                }
                return
            } else {
                if (earth) {
                    navgate('/page2')
                    return
                }
            }
            const moons = intersects.filter((obj) => obj.object.privateType == 'column')
            if (moons.length == 0) {
                return
            }
            const cr = moons.reduce((obj, item) => {
                if (!obj) {
                    return item
                }
                if (obj.distance > item.distance) {
                    return item
                } else {
                    return obj
                }
            }, undefined)
            if (!earth || earth.distance > cr.distance) {
                const obj = cr.object.config
                setIsModalOpen(true)
                setHtml(`s${obj.num + 1}`)
                config.mask = true
            }
        }
        const canvasDom = document.getElementById('canvas2')
        canvasDom?.addEventListener('click', handleClick)

        let mouseXOnMouseDown
        let targetRotationOnMouseDown

        const onDocumentMouseDown = (event) => {
            if (config.animationType) {
                config.rotationY = false
                isMove = false
                config.isDown = true
                mouseXOnMouseDown = event.layerX
                targetRotationOnMouseDown = group.rotation.y
            }
        }

        const onDocumentMouseMove = (event) => {
            if (config.isDown) {
                //移动的时候鼠标相对位置
                const mouseX = event.layerX - mouseXOnMouseDown
                if (Math.abs(mouseX) > 3) {
                    isMove = true
                    //移动的时候旋转的角度 = 刚按下鼠标的角度+移动的位置-鼠标按下时的位置
                    group.rotation.y = targetRotationOnMouseDown + mouseX * 0.002
                }
            }
        }

        const onDocumentMouseUp = (e) => {
            config.rotationY = true
            config.isDown = false
            // handleClick()
            setTimeout(() => {
                isMove = false
            }, 100)
        }

        function onPointerMove(event) {
            const dom = document.getElementById('root')
            const scrollTop = dom?.scrollTop
            // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
            pointer.x = ((event.clientX - rleft) / innerWidth) * 2 - 1
            pointer.y = -((event.clientY + scrollTop) / innerHeight) * 2 + 1
        }

        const onDocumentTouchStart = (event) => {
            if (event.touches.length === 1) {
                onPointerMove(event.touches[0])
                if (config.animationType) {
                    config.rotationY = false
                    isMove = false
                    config.isDown = true
                    mouseXOnMouseDown = event.touches[0].pageX
                    targetRotationOnMouseDown = group.rotation.y
                }
            }
        }
        const onDocumentTouchMove = (event) => {
            if (event.touches.length === 1) {
                if (config.isDown) {
                    isMove = true
                    const mouseX = event.touches[0].pageX
                    group.rotation.y = targetRotationOnMouseDown + (mouseX - mouseXOnMouseDown) * 0.002
                }
            }
        }

        if (isMobile()) {
            canvasDom?.addEventListener('touchstart', onDocumentTouchStart, false)
            canvasDom?.addEventListener('touchmove', onDocumentTouchMove, false)
            canvasDom?.addEventListener('touchend', onDocumentMouseUp, false)
        } else {
            canvasDom?.addEventListener('mousedown', onDocumentMouseDown)
            canvasDom?.addEventListener('mousemove', onDocumentMouseMove)
            canvasDom?.addEventListener('mouseup', onDocumentMouseUp)
            canvasDom?.addEventListener('mouseout', onDocumentMouseUp)
            window.addEventListener('pointermove', onPointerMove)
        }
        init()
        animate()
    }, [])

    const handleCancel = () => {
        setIsModalOpen(false)
        config.mask = false
    }

    const sp = s[_html]
    return (
        <div
            className={styles.main}
            key="main2"
            id="main"
            // style={{ width: innerWidth, height: innerHeight }}
        >
            <video
                muted
                loop
                autoPlay
                className={styles.video}
                playsInline={true}
                id="video"
                // @ts-ignore
                webkit-playsinline="true"
                src="/07.mp4"
            />
            {/* <video muted className={styles.video2} id="video2" src="/07.mp4" /> */}
            <div id="canvas2" />
            <div id="loading" className={styles.progress} style={{ top: innerHeight / 2 }}>
                <div className={styles.loading}>Loading</div>
            </div>
            {isModalOpen && (
                <div className={styles.md}>
                    <div className={styles.mdb} />
                    <div className={styles.tbg}>
                        <div className={styles.close} onClick={handleCancel}>
                            ×
                        </div>
                        <div className={styles.tinline}>
                            <Swiper
                                // spaceBetween={50}
                                // slidesPerView={1}
                                modules={[Navigation, Pagination]}
                                navigation
                                pagination={{ clickable: true }}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                                className={styles.swiper}
                            >
                                {sp.map((d) => {
                                    return (
                                        <SwiperSlide key={d.src} className={styles.slide}>
                                            {d.type == 'image' && <img src={d.src} alt="" className={styles.slidei} />}
                                            {d.type == 'video' && (
                                                <video
                                                    src={d.src}
                                                    controls // @ts-ignore
                                                    webkit-playsinline="true"
                                                    playsInline={true}
                                                    className={styles.slidei}
                                                />
                                            )}
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                    <video
                        muted
                        autoPlay
                        // @ts-ignore
                        webkit-playsinline="true"
                        playsInline={true}
                        className={`${styles.tbg} ${styles.tvideo}`}
                        src="/tb.mp4"
                    />
                </div>
            )}
        </div>
    )
}

export default App
