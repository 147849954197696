import _a1 from './a1.png'
import _a2 from './a2.png'
import _a3 from './a3.png'
import _a4 from './a4.png'
import _a5 from './a5.png'
import _a6 from './a6.png'
import _a7 from './a7.png'
import _a8 from './a8.png'
import _b2 from './b2.png'
import _bd from './bd.png'
import _bt from './bt.png'
import _i1 from './i1.png'
import _lensflare0 from './lensflare0.png'
import _lensflare0_alpha from './lensflare0_alpha.png'
// import _map3 from './mp4.jpg'
import _q1 from './q1.png'

export const b2 = _b2
export const bt = _bt
export const bd = _bd
export const a1 = _a1
export const a2 = _a2
export const a3 = _a3
export const a4 = _a4
export const a5 = _a5
export const a6 = _a6
export const a7 = _a7
export const a8 = _a8
export const i1 = _i1
export const q1 = _q1
export const map = "/images/earth.jpg"
export const lensflare0 = _lensflare0
export const lensflare0_alpha = _lensflare0_alpha
