import * as THREE from 'three'

export const addDirLight = (scene) => {
    const ambient = new THREE.AmbientLight(0xffffff, 3);
    // const ambient = new THREE.AmbientLight(new THREE.Color(0xffffff, 0.5)); //环境光
    scene.add(ambient);
    // const light = new THREE.PointLight(0xff0000, 1, 100);
    // light.position.set(0, 0, 0)

    let pointLight = new THREE.PointLight(new THREE.Color(0xffffff), 5, 3000, 0); //点光源
    pointLight.visible = true;
    pointLight.position.set(0, 0, 0); //点光源在原点充当太阳
    scene.add(pointLight); //点光源添加到场景中
}
