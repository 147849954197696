import * as TWEEN from '@tweenjs/tween.js'

export const flyTo = (oldP, oldT, newP, newT, time, camera, orbitControls, callBack) => {
    if (TWEEN) {
        let tween = new TWEEN.Tween({
            x1: oldP.x, // 相机x
            y1: oldP.y, // 相机y
            z1: oldP.z, // 相机z
            x2: oldT.x, // 控制点的中心点x
            y2: oldT.y, // 控制点的中心点y
            z2: oldT.z, // 控制点的中心点z
        })
        tween.to(
            {
                x1: newP.x,
                y1: newP.y,
                z1: newP.z,
                x2: newT.x,
                y2: newT.y,
                z2: newT.z,
            },
            time,
        )
        tween.onUpdate(function (object) {
            camera.position.set(object.x1, object.y1, object.z1)
            orbitControls.target.x = object.x2
            orbitControls.target.y = object.y2
            orbitControls.target.z = object.z2
            orbitControls.update()
        })
        tween.onComplete(function () {
            callBack && callBack()
        })
        tween.easing(TWEEN.Easing.Cubic.InOut)
        tween.start()
    }
}