
// 创建土星
import * as THREE from 'three'

export const createSaturn = (data, scene, mp2) => {
    let saturnGroup = new THREE.Group(); //土星的组
    let saturnGeometry = new THREE.SphereGeometry(data.size, 100, 100); //土星几何体
    let saturnMaterial = new THREE.MeshLambertMaterial({
        // map:data.texture,
        map: mp2[data.name],
        // map: new THREE.TextureLoader().load(data.mapImg), //土星材质
    });
    let saturnMesh = new THREE.Mesh(saturnGeometry, saturnMaterial); //土星网格
    saturnGroup.add(saturnMesh); //将土星网格添加到地球组中
    //创建土星环1
    let saturnTrackGeometry1 = new THREE.RingGeometry( //圆环几何体
        data.size + 10,
        data.size + 25,
        100
    );
    let saturnTrackMaterial1 = new THREE.MeshLambertMaterial({
        transparent: true,
        opacity: 0.8,
        color: 0xc0ad87,
        side: THREE.DoubleSide,
    });
    let saturnTrackMesh1 = new THREE.Mesh(
        saturnTrackGeometry1,
        saturnTrackMaterial1
    );
    saturnTrackMesh1.rotation.set(0.5 * Math.PI, 0, 0);
    //创建土星环2
    let saturnTrackGeometry2 = new THREE.RingGeometry( //圆环几何体
        data.size + 26,
        data.size + 30,
        100
    );
    let saturnTrackMaterial2 = new THREE.MeshLambertMaterial({
        transparent: true,
        opacity: 0.5,
        color: 0xc0ad87,
        side: THREE.DoubleSide,
    });
    let saturnTrackMesh2 = new THREE.Mesh(
        saturnTrackGeometry2,
        saturnTrackMaterial2
    );
    saturnTrackMesh2.rotation.set(0.5 * Math.PI, 0, 0);
    //创建土星环3
    let saturnTrackGeometry3 = new THREE.RingGeometry( //圆环几何体
        data.size + 30.1,
        data.size + 32,
        100
    );
    let saturnTrackMaterial3 = new THREE.MeshLambertMaterial({
        transparent: true,
        opacity: 0.3,
        color: 0xc0ad87,
        side: THREE.DoubleSide,
    });
    let saturnTrackMesh3 = new THREE.Mesh(
        saturnTrackGeometry3,
        saturnTrackMaterial3
    );
    saturnTrackMesh3.rotation.set(0.5 * Math.PI, 0, 0);
    saturnGroup.add(saturnTrackMesh1); //将网格添加到组中
    saturnGroup.add(saturnTrackMesh2);
    saturnGroup.add(saturnTrackMesh3);
    saturnGroup.name = data.name; //网格名字
    saturnGroup.planetMsg = data;
    saturnGroup.isPlanet = true; //标识为星球
    saturnGroup.angle = 0; //添加初始角度
    //球体位置
    saturnGroup.position.set(
        data.position[0],
        data.position[1],
        data.position[2]
    );
    scene.add(saturnGroup);
}