export const s = {
    s1: [ // 且力集团介绍
        {
            type: 'image',
            src: '/images/s1/1.png',
        },
        {
            type: 'image',
            src: '/images/s1/2.png',
        },
        {
            type: 'image',
            src: '/images/s1/3.png',
        },
        {
            type: 'video',
            src: 'http://sdy.youpeiyun.cc/videofiles/且力集团(原视频).mp4',
        },
    ],
    s2: [ // 闪垫金服介绍
        {
            type: 'image',
            src: '/images/s2/1.png',
        },
        {
            type: 'image',
            src: '/images/s2/2.png',
        },
        {
            type: 'video',
            src: 'http://sdy.youpeiyun.cc/videofiles/闪垫金服(原视频).mp4',
        },
    ],
    s3: [ // 联资纵市
        {
            type: 'image',
            src: '/images/s3/1.png',
        },
        {
            type: 'image',
            src: '/images/s3/2.png',
        },
        {
            type: 'image',
            src: '/images/s3/3.png',
        },
        {
            type: 'image',
            src: '/images/s3/4.png',
        },
        {
            type: 'image',
            src: '/images/s3/5.png',
        },
        {
            type: 'image',
            src: '/images/s3/6.png',
        },
        {
            type: 'image',
            src: '/images/s3/7.png',
        },
        {
            type: 'image',
            src: '/images/s3/8.png',
        },
        {
            type: 'image',
            src: '/images/s3/9.png',
        },
        {
            type: 'image',
            src: '/images/s3/10.png',
        },
        {
            type: 'image',
            src: '/images/s3/11.png',
        },
        {
            type: 'video',
            src: 'http://sdy.youpeiyun.cc/videofiles/联资纵市(原视频).mp4',
        },
    ],
    s4: [ // 签约讲解
        {
            type: 'image',
            src: '/images/s4/1.png',
        },
        {
            type: 'image',
            src: '/images/s4/2.png',
        },
        {
            type: 'image',
            src: '/images/s4/3.png',
        },
        {
            type: 'image',
            src: '/images/s4/4.png',
        },
        {
            type: 'image',
            src: '/images/s4/5.png',
        },
        {
            type: 'image',
            src: '/images/s4/6.png',
        },
        {
            type: 'image',
            src: '/images/s4/7.png',
        },
        {
            type: 'video',
            src: 'http://sdy.youpeiyun.cc/videofiles/签约讲解(原视频).mp4',
        },
    ],
    s5: [ // 合作模式
        {
            type: 'image',
            src: '/images/s5/1.png',
        },
        {
            type: 'image',
            src: '/images/s5/2.png',
        },
        {
            type: 'image',
            src: '/images/s5/3.png',
        },
        {
            type: 'image',
            src: '/images/s5/4.png',
        },
        {
            type: 'video',
            src: 'http://sdy.youpeiyun.cc/videofiles/合作模式(原视频).mp4',
        },
    ],
    s6: [ // 进件标准
        {
            type: 'image',
            src: '/images/s6/1.png',
        },
        {
            type: 'image',
            src: '/images/s6/2.png',
        },
    ],
    s7: [ // 系统演示
        {
            type: 'image',
            src: '/images/s7/1.png',
        },
        {
            type: 'image',
            src: '/images/s7/2.png',
        },

    ],
    s8: [ // 合同范本
        {
            type: 'image',
            src: '/images/s8/1.png',
        },
        {
            type: 'image',
            src: '/images/s8/2.png',
        },
        {
            type: 'image',
            src: '/images/s8/3.png',
        },
        {
            type: 'image',
            src: '/images/s8/4.png',
        },
    ],
}