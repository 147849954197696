import React from 'react'

import styles from './content.module.less'

const Page = ({ children, title, right = <div />, ...rest }) => {
    return (
        <div className={styles.c} {...rest}>
            <div className={styles.t1}>
                <div className={styles.t2}>{title}</div>
                {right}
            </div>
            {children}
        </div>
    )
}

export default Page
