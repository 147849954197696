
import * as THREE from 'three'

import { createEarth } from './createEarth'
import { createSaturn } from './createSaturn'
import { createSun } from './createSun'
import { createVenus } from './createVenus'

export const createSphere = (data, scene, mp2) => {
    if (data.name == '太阳') {
        createSun(data, scene, mp2)
    } else if (data.name == '地球') {
        createEarth(data, scene, mp2)
    } else if (data.name == '金星') {
        createVenus(data, scene, mp2)
    } else if (data.name == '土星') {
        createSaturn(data, scene, mp2)
    } else {
        //其他球体
        let sphereGeometry = new THREE.SphereGeometry(data.size, 100, 100) //球体几何体
        //球体材质
        let sphereMaterial = new THREE.MeshLambertMaterial({
            // map: data.texture,
            map: mp2[data.name],
            // map:  new THREE.TextureLoader().load(data.mapImg),
        })
        sphereMaterial.map.colorSpace = THREE.SRGBColorSpace
        let sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial) //生成球体网格

        // sphereMesh.castShadow = true; //default is false
        // sphereMesh.receiveShadow = false; //default
        sphereMesh.name = data.name //网格名字
        sphereMesh.planetMsg = data
        sphereMesh.isPlanet = true //标识为星球
        sphereMesh.angle = 0 //添加初始角度
        //球体位置
        sphereMesh.position.set(
            data.position[0], //x
            data.position[1], //y
            data.position[2], //z
        )
        scene.add(sphereMesh) //球体添加到场景中
    }
}
