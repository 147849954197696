import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, useRoutes } from 'react-router-dom'

import Index from './pages/Index'
import Page1 from './pages/Page1'
import Page2 from './pages/Page2'
import './main.less'

const Router = () => {
    const router = useRoutes([
        { path: '/', element: <Index /> },
        { path: '/page1', element: <Page1 /> },
        { path: '/page2', element: <Page2 /> },
    ])
    return router
}

const App = () => {
    return (
        <HashRouter>
            <Router />
        </HashRouter>
    )
}
// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
