import React from 'react'

import { Tw } from '../Tw'

import styles from './styles.module.less'

export const Modal4 = ({ data = {} }: any) => {
    const { data4 = [], data5 = [] } = data

    const maxD1 = data4.map((d) => Number(d.d1))
    const maxD3 = data4.map((d) => Number(d.d3))
    const max1 = Math.max(...maxD1)
    const max3 = Math.max(...maxD3)

    const maxD5 = data5.map((d) => Number(d.d2))
    const max5 = Math.max(...maxD5)

    return (
        <div className={styles.modal4}>
            <div className={styles.c1}>
                <div className={styles.m}>
                    <div className={styles.m1}>均单额</div>
                    <div className={styles.m2}>
                        <Tw value={data.data1} />
                    </div>
                    <div className={styles.m3}>万</div>
                </div>
                <div className={styles.m}>
                    <div className={styles.m1}>均途期</div>
                    <div className={styles.m2}>
                        <Tw value={data.data2} />
                    </div>
                    <div className={styles.m3}>天</div>
                </div>
                <div className={styles.m}>
                    <div className={styles.m1}>日均放款额</div>
                    <div className={styles.m2}>
                        <Tw value={data.data3} />
                    </div>
                    <div className={styles.m3}>万</div>
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.c2}>
                <div className={styles.c3}>
                    <div className={styles.m01}>
                        <div className={styles.m1}>放款额</div>
                        <div className={styles.m3}>
                            {data4.map((d, i) => {
                                return (
                                    <div
                                        key={i}
                                        style={{ width: `${Number(d.d1 * 100) / max1}%` }}
                                        className={styles.m31}
                                    >
                                        {d.d1}万
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.m02}>
                        <div className={styles.m1}>业务编号</div>
                        <div className={styles.m2}>
                            {data4.map((d, i) => {
                                return (
                                    <div key={i} className={styles.m22}>
                                        {d.d2}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.m03}>
                        <div className={styles.m1}>途期</div>
                        <div className={styles.m3}>
                            {data4.map((d, i) => {
                                return (
                                    <div
                                        key={i}
                                        style={{ width: `${Number(d.d3 * 100) / max3}%` }}
                                        className={styles.m31}
                                    >
                                        {d.d3}天
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.c4}>
                    <div className={styles.m02}>
                        <div className={styles.m1}>日期</div>
                        <div className={styles.m2}>
                            {data5.map((d, i) => {
                                return (
                                    <div key={i} className={styles.m22}>
                                        {d.d1}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.m03}>
                        <div className={styles.m1}>放款额</div>
                        <div className={styles.m3}>
                            {data5.map((d, i) => {
                                return (
                                    <div
                                        key={i}
                                        style={{ width: `${Number(d.d2 * 100) / max5}%` }}
                                        className={styles.m31}
                                    >
                                        {d.d2}万
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
