import * as TWEEN from '@tweenjs/tween.js'
import * as D3 from 'd3'
import React, { useEffect } from 'react'
import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'

// import { a1 } from '@/images'

import map from './map.jpg'
import styles from './map.module.less'

const color1 = 0x062e60
const color2 = 0x94e9f3
const color3 = 0x37cbe6
const jsonUrl = '/100000_full.json'
const mapUrl = '/data/map.json'

// const innerWidth = window.innerWidth < 1920 ? window.innerWidth : 1920
// const innerHeight = window.innerHeight < 1080 ? window.innerHeight : 1080
const innerWidth = 1920
const innerHeight = 1080
const extrudeSettings = {
    depth: 4,
    bevelEnabled: false,
    // steps: 2,
    // bevelSize: 0,
    // bevelSegments: 5,
    // bevelThickness: 0.1,
}

function isMobile() {
    let userAgentInfo = navigator.userAgent
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    let getArr = Agents.filter((i) => userAgentInfo.includes(i))
    return getArr.length ? true : false
}

const provinceNames = {
    山东省: '山东',
    新疆维吾尔自治区: '新疆',
    西藏自治区: '西藏',
    广西壮族自治区: '广西',
    内蒙古自治区: '内蒙古',
    宁夏回族自治区: '宁夏',
    澳门特别行政区: '澳门',
    香港特别行政区: '香港',
}

const handleProj = D3.geoMercator().center([109, 34.5]).scale(80).translate([0, 0]) // d3投影转换函数

const Map = () => {
    useEffect(() => {
        let INTERSECTED

        const config = {
            clientX: 0,
            clientY: 0,
        }
        const mainDom: any = document.getElementById('main')
        const rleft = mainDom.getBoundingClientRect().left

        const canvasDom: any = document.getElementById('mapcanvas')
        const scence = new THREE.Scene()
        const renderer = new THREE.WebGLRenderer()
        const pointer = new THREE.Vector2(-1, -1)
        const raycaster = new THREE.Raycaster()
        const mapContainer = new THREE.Group()
        const camera = new THREE.PerspectiveCamera(45, innerWidth / innerHeight, 1, 1000)
        const ambLight = new THREE.AmbientLight('#ffffff', 0.3) // 基本光源
        scence.add(ambLight) // 向场景中添加光源
        renderer.setSize(innerWidth, innerHeight)
        renderer.setClearColor(0x000, 0)

        camera.position.set(0, -50, 120)
        camera.lookAt(0, 0, 0)

        mapContainer.position.x = 6
        mapContainer.position.y = -10
        scence.add(mapContainer)

        const labelRenderer = new CSS2DRenderer()
        labelRenderer.className = 'labelRenderer'
        labelRenderer.domElement.style.position = 'absolute'
        labelRenderer.domElement.style.top = '0px'
        labelRenderer.setSize(innerWidth, innerHeight)
        canvasDom.appendChild(labelRenderer.domElement)
        canvasDom.appendChild(renderer.domElement)

        // new OrbitControls(camera, labelRenderer.domElement)

        const creatDepthPolygon = (coordinate) => {
            const shape = new THREE.Shape()
            // const lineGeometry = new THREE.Geometry()
            coordinate.forEach((item, index) => {
                // 每一个item都是MultiPolygon中的一个polygon
                const [x, y] = handleProj(item)
                if (index === 0) {
                    shape.moveTo(x, -y)
                } else {
                    shape.lineTo(x, -y)
                }
                // lineGeometry.vertices.push(new THREE.Vector3(x, -y, 4.01))
            })

            let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings)
            // const material = new THREE.MeshPhongMaterial({ color: 0x9cceee })

            // const material = new THREE.MeshBasicMaterial({
            //     color: new THREE.Color(Math.random() * 0xffffff), // 每个省随机赋色
            //     transparent: true,
            //     opacity: 0.5
            // })
            // const textureLoader = new THREE.TextureLoader()
            // const textureb = textureLoader.load(a1)
            const material = new THREE.MeshBasicMaterial({
                color: color1,
                // map: textureb,
                transparent: true,
                opacity: 1,
            })
            const material1 = new THREE.MeshBasicMaterial({
                color: color2,
                transparent: true,
                opacity: 1,
            })

            const mesh = new THREE.Mesh(geometry, [material, material1])
            mesh.position.z = -2
            shape.autoClose = true
            const points = shape.getPoints()
            const geometryPoints = new THREE.BufferGeometry().setFromPoints(points)
            let line = new THREE.Line(geometryPoints, new THREE.LineBasicMaterial({ color: 0x3db5ce }))
            line.position.z = 2.1
            return [mesh, line]
        }

        const addObj = (coord, province, feature, mapData) => {
            coord.forEach((coordinate) => {
                const [extrudeMesh, line] = creatDepthPolygon(coordinate)
                extrudeMesh.properties = feature.properties.name
                extrudeMesh.city = mapData?.city
                extrudeMesh.privateType = 'province'
                // extrudeMesh.privateColor = Math.random() * 0xffffff
                extrudeMesh.privateColor = color1
                province.add(extrudeMesh)
                province.add(line)
            })
        }

        const addRingMark = (item, province, mapData) => {
            const [x, y] = handleProj(item)
            let cityWaveMaterial = new THREE.MeshBasicMaterial({
                color: mapData?.color || 0xff0000,
                transparent: true,
                opacity: 0,
                side: THREE.DoubleSide,
            })
            // let mesh = new THREE.Mesh(new THREE.RingGeometry(0.5, 0.6, 20, 5, 0, Math.PI * 2), cityWaveMaterial)
            let mesh = new THREE.Mesh(new THREE.CircleGeometry(0.5, 32, 0, Math.PI * 2), cityWaveMaterial)
            mesh.privateType = 'mark'
            mesh.position.set(x, -y, 2.1)

            new TWEEN.Tween({ opacity: 1, scaleX: 0 })
                .repeat(Infinity)
                .to({ opacity: 0, scaleX: 2 }, 1600)
                .onUpdate(function (obj) {
                    // @ts-ignore
                    const { opacity, scaleX } = obj
                    mesh.material.opacity = opacity
                    mesh.scale.set(scaleX, scaleX, scaleX)
                })
                .easing(TWEEN.Easing.Sinusoidal.InOut)
                .start()

            province.add(mesh)
        }

        const addTip = (feature, province, name) => {
            const [x, y] = handleProj(feature.properties.centroid || feature.properties.center)

            const moonDiv = document.createElement('div')
            moonDiv.className = styles.label
            moonDiv.textContent = name
            const moonLabel = new CSS2DObject(moonDiv)
            moonLabel.position.set(x, -y, 4)
            moonLabel.center.set(0.5, 0.5)
            province.add(moonLabel)
        }

        const addProvince = (feature, mapData) => {
            const name =
                provinceNames[feature.properties.name] || feature.properties.name.replace('省', '').replace('市', '')

            const province = new THREE.Object3D()
            province.properties = feature.properties.name // 省份名称
            const coordinates = feature.geometry.coordinates // 省份坐标信息
            if (feature.geometry.type === 'MultiPolygon') {
                coordinates.forEach((coord, index) => {
                    if (
                        // ['海南省', '台湾省', '广东省', '辽宁省', '山东省'].includes(feature.properties.name) &&
                        index > 0
                    ) {
                        return
                    }
                    addObj(coord, province, feature, mapData[name])
                })
            }
            if (feature.geometry.type === 'Polygon') {
                addObj(coordinates, province, feature, mapData[name])
            }
            if (!['澳门特别行政区', '香港特别行政区'].includes(feature.properties.name)) {
                addRingMark(feature.properties.centroid || feature.properties.center, province, mapData[name])
                addTip(feature, province, name)
            }
            mapContainer.add(province)
        }

        const getJsonData = async () => {
            let jsonData = await fetch(jsonUrl).then((res) => res.json())
            let mapData = await fetch(mapUrl).then((res) => res.json())
            const feaureList = jsonData.features
            feaureList.forEach((feature) => {
                // 每个feature都代表一个省份
                if (!feature.properties.name) {
                    return
                }
                addProvince(feature, mapData)
            })
        }

        getJsonData()

        const move = () => {
            raycaster.setFromCamera(pointer, camera)
            // const vv = new THREE.Spherical().setFromVector3(camera.position)
            // 计算物体和射线的焦点
            const intersects = raycaster.intersectObjects(scence.children)
            if (intersects.length == 0) {
                return
            }
            const tips: any = document.getElementById('tips')
            const meshs = intersects.filter((obj) => obj.object.privateType == 'province')
            if (meshs.length > 0 && meshs[0].object.city) {
                if (INTERSECTED != meshs[0].object) {
                    // tips.position.set(pointer.x, pointer.y, 4)
                    if (INTERSECTED) {
                        // INTERSECTED.material[0].color.set(color1)
                        INTERSECTED.material[0].color.set(INTERSECTED.privateColor)
                    }
                    const citys = meshs[0].object.city

                    tips.style.display = 'block'
                    const html = citys
                        .map((d) => {
                            return `<div class="${styles.tip}">
                        <p>城市名称：${d.城市名称}</p>
                        <p>放贷总量：${d.放款总量}</p>
                        <p>单月放贷量：${d.单月放款量}（占比${d.占比}）</p>
                        <p>城市公司名：${d.城市公司名称 || '暂无'}</p>
                        <p>公司规横：${d.公司规模}</p>
                        </div>`
                        })
                        .join('')
                    let cls = styles.tips1
                    if (citys.length == 2) {
                        cls = styles.tips2
                    } else if (citys.length > 2) {
                        cls = styles.tips3
                    }

                    tips.innerHTML = ` 
                    <div class="${styles.province}">
                    ${meshs[0].object.properties}
                    </div>
                    <div class="${cls}">
                    ${html}
                    </div>`
                    INTERSECTED = meshs[0].object
                    INTERSECTED.material[0].color.set(color3)
                }
                tips.style.left = config.clientX + 'px'
                tips.style.top = config.clientY + 'px'
            } else {
                if (INTERSECTED) {
                    INTERSECTED.material[0].color.set(INTERSECTED.privateColor)
                }
                tips.style.display = 'none'
                INTERSECTED = null
            }
        }

        function animate() {
            requestAnimationFrame(animate)
            move()
            renderer.render(scence, camera)
            labelRenderer.render(scence, camera)
            TWEEN.update()
        }

        animate()

        const onDocumentTouchStart = (event) => {
            if (event.touches.length === 1) {
                onPointerMove(event.touches[0])
            }
        }

        const onPointerMove = (event) => {
            config.clientX = event.clientX
            config.clientY = event.clientY
            // console.log('clientX', event.clientX)
            // console.log(window.innerWidth)
            // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
            const dom = document.getElementById('root')
            const scrollTop = dom?.scrollTop
            pointer.x = ((event.clientX - rleft) / innerWidth) * 2 - 1
            pointer.y = -((event.clientY + scrollTop) / innerHeight) * 2 + 1
        }

        if (isMobile()) {
            canvasDom?.addEventListener('touchstart', onDocumentTouchStart, false)
        } else {
            window.addEventListener('pointermove', onPointerMove)
        }
        return () => {
            window.removeEventListener('touchstart', onDocumentTouchStart, false)
            window.removeEventListener('pointermove', onPointerMove, false)
        }
    }, [])
    return (
        <div id="mapcanvas" style={{ width: innerWidth, height: innerHeight }}>
            <div id="tips" className={styles.tips}>
                tips
            </div>
            <img className={styles.map} src={map} alt="" />
        </div>
    )
}

export default Map
