import React from 'react'

import styles from './styles.module.less'

const Modal = ({ children, setData, title }) => {
    return (
        <div className={styles.md}>
            <div className={styles.mdb} />
            <div className={styles.tbg}>
                <div className={styles.close} onClick={setData}>
                    ×
                </div>
                <div className={styles.title2}>
                    <span className={styles.title21}>{title}</span>
                </div>
                <div className={styles.tinline}>{children}</div>
            </div>
            <video
                muted
                autoPlay
                playsInline={true} // @ts-ignore
                webkit-playsinline="true"
                className={`${styles.tbg} ${styles.tvideo}`}
                src="/tb.mp4"
            />
        </div>
    )
}

export default Modal
