
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls' //鼠标控制

export const createOrbitControls = (camera, renderer) => {
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.enablePan = false; //右键平移拖拽
    orbitControls.enableZoom = true; //鼠标缩放
    orbitControls.enableDamping = true; //滑动阻尼
    orbitControls.dampingFactor = 0.05; //(默认.25)
    orbitControls.minDistance = 100; //相机距离目标最小距离
    orbitControls.maxDistance = 2700; //相机距离目标最大距离
    // orbitControls.maxPolarAngle = (Math.PI / 4) * 3; //y旋转角度范围
    // orbitControls.minPolarAngle = Math.PI / 4;
    orbitControls.autoRotate = true; //自转(相机)
    orbitControls.autoRotateSpeed = 0; //自转速度

    return orbitControls
}