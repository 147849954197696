import React, { useEffect } from 'react'
import Swiper from 'swiper'
import { Autoplay, Pagination, Virtual } from 'swiper/modules'

import Content from './Content'
import styles from './table.module.less'
import Title from './Title'

import 'swiper/less'
import 'swiper/less/pagination'
import 'swiper/less/autoplay'

const Table = ({ list, setData }) => {
    useEffect(() => {
        if (list.length == 0) {
            return
        }
        new Swiper('.swiper', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Virtual],
            direction: 'vertical',
            loop: true,
            autoplay: true,
            slidesPerView: 7,
            spaceBetween: 0,
        })
    }, [list])

    return (
        <Content title="审批实况" right={<Title onClick={() => setData({ visible1: true })}>更多</Title>}>
            <div className={styles.table}>
                <div className={styles.thead}>
                    <div className={styles.d1}>城市地区</div>
                    <div className={styles.d2}>程序</div>
                    <div className={styles.d3}>状态</div>
                    <div className={styles.d4}>审核人</div>
                </div>
                <div className={`swiper ${styles.swiper}`}>
                    <div className="swiper-wrapper">
                        {list.map((d, i) => {
                            return (
                                <div key={i} className={`swiper-slide ${styles.slide}`}>
                                    <div className={styles.d1}>{d['城市地区']}</div>
                                    <div className={styles.d2}>{d['程序']}</div>
                                    <div className={styles.d3}>{d['状态']}</div>
                                    <div className={styles.d4}>{d['审核人']}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Content>
    )
}

export default Table
