// 创建球体轨迹
import * as THREE from 'three'

export const createTrack = (data, scene) => {
    if (data.name == "太阳") {
        //去除太阳中心由圆环形成的圆形
        return;
    }
    //创建轨迹
    let trackGeometry = new THREE.RingGeometry( //圆环几何体
        data.position[0],
        data.position[0] + 2,
        1000
    );
    //圆环材质
    let trackMaterial = new THREE.LineBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
    });
    let trackMesh = new THREE.Mesh(trackGeometry, trackMaterial);
    trackMesh.position.set(0, 0, 0); //轨道位置
    trackMesh.rotation.set(0.5 * Math.PI, 0, 0); //旋转轨道至水平
    scene.add(trackMesh);
}